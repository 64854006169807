/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;

@import '@angular/material/theming';
@import './scss/core.scss';
@import './scss/bleacr-card.scss';
@import './scss/utility.scss';
@import './scss/match-status.scss';
@import './scss/host-deep.scss';

@include mat.core();

$angular-primary: mat.define-palette(mat.$teal-palette, 500, 100, 900);
$angular-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

$angular-warn: mat.define-palette(mat.$red-palette);

$angular-default-theme: mat.define-light-theme(
    (
        color: (
            primary: $angular-primary,
            accent: $angular-accent,
            warn: $angular-warn
        )
    )
);

@include mat.all-component-themes($angular-default-theme);

$angular-dark-theme: mat.define-dark-theme(
    (
        color: (
            primary: $angular-primary,
            accent: $angular-accent,
            warn: $angular-warn
        )
    )
);

.darkMode {
    @include mat.all-component-colors($angular-dark-theme);
}

a {
    color: $link-color !important;
}

.mat-tab-link {
    color: #ffffff !important;
    opacity: 0.9 !important;
}

mat-dialog-container {
    background-color: var(--background) !important;
    padding: 0 !important;
}

.no-results-panel {
    height: 7vh !important;
    background-color: #000000;
}

.search-auto {
    min-height: 85vh !important;
    background-color: var(--background);
    mat-optgroup {
        mat-option {
            line-height: 25px !important;
            margin: 15px 10px;
            padding: 40px 20px;
            border-radius: 6px;
            color: #ffffff !important;
        }
        .mat-optgroup-label {
            font-size: 1.5rem;
            color: var(--text-color);
        }
    }
}

.active {
    img.tab_select.selected {
        display: block;
    }
    img.tab_select.unselected {
        display: none;
    }
}

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
    background-color: #fafafa;
}

.privacy-dialog {
    z-index: 11;
    padding: 20px;
}

.tennisone-arrow {
    background-color: $bleachr-purple !important;
    opacity: 1 !important;
    height: 3em !important;
    width: 3em !important;

    &:disabled {
        opacity: 0.3 !important;
    }

    svg {
        fill: $text-color !important;
        height: 1.3em !important;
        width: 1.3em !important;
    }
}

.popupContainer {
    z-index: 12 !important;
}

.mat-expansion-indicator::after {
    color: unset !important;
}

.mat-expansion-panel {
    margin: 5px 0 !important;
}

.mat-tab-header-pagination-chevron {
    border-color: #e4e4e4 !important;
}

.custom-overlay, .custom-backdrop {
    width: var(--overlay-width);
    margin-left: calc(8em + 260px);
    display: flex;
    margin-top: 80px;
    height: calc(100vh - 80px);
}

@media only screen and (max-width: 500px) { 
    .privacy-dialog {
        width: 85% !important;
        height: 65% !important;
    }       
}

@media (prefers-color-scheme: light) {
    .mat-tab-group.mat-primary .mat-ink-bar,
    .mat-tab-nav-bar.mat-primary .mat-ink-bar {
        background-color: #484DD0;
    }

    mat-dialog-container {
        padding: 0 !important;
    }
    .mat-expansion-panel {
        background-color: #f7f7f7 !important;
        color: #101010 !important;
    }
    .mat-tab-header-pagination-chevron {
        border-color: #2d2d2d !important;
    }
    .no-results-panel {
        background-color: $light-background;
        color: $light-text-color;
    }
    .mat-tab-link {
        color: #101010 !important;
        opacity: 0.9 !important;
    }
}

.alt-menu {
    display: none;
}

