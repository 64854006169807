.bleachr-card {
    position: relative;
    &::before {
        display: block;
        content: '';
        width: 100%;
    }

    .placeholder-background-image {
        background-size: 100% 100%;
        width: 100%;
        height: 100%;
    }

    // OVERIDES

    &.NineByOne {
        .bleachr-card-headline {
            .headline-text-container {
                font-size: 16px;
                .sub-title {
                    font-size: 14px;
                }
            }
        }
    }

    &.TenByOne {
        .bleachr-card-headline {
            height: 40px;

            .headline-text-container {
                font-size: 16px;
                .sub-title {
                    font-size: 14px;
                }
            }
        }
    }

    hr {
        width: calc(100% - 2rem);
        border: 0;
        height: 1px;
        background-color: #9e9e9e;
        margin: 0 auto;
    }

    .bleachr-card-headline {
        position: absolute;
        display: flex;
        left: 0px;
        top: 0px;
        height: 45px;
        width: 95%;
        z-index: 1;

        .headline-text-container {
            display: flex;
            width: 80%;
            flex-direction: column;
            justify-content: center;
            padding-left: 15px;
            font-size: 20px;

            .sub-title {
                font-size: 16px;
            }
        }
    }

    .content-container {
        position: relative;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 0;

        img {
            width: 100%;
        }
    }

    footer {
        width: 100%;
        position: absolute;
        bottom: 0;
        z-index: 1;
        background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5), transparent);

        p {
            margin: 0.55rem 1rem;
        }
    }
}
