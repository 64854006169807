.match-status {
    padding: 2px 8px;
    border-radius: 16px;
    font-weight: 600;

    &.finished {
        background-color: rgb(197, 197, 201);
        color: black;
    }

    &.playing {
        background-color: rgb(238, 50, 36);
        color: #fafafa;
    }
}
