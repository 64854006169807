//
// PROPS
//
$border-radius: 6px;
$score-header-height: 120px;

//
// COLORS
//
$bleachr-purple: #484DD0;

$success: #4caf50;
$tennis-ball: #c9f364;
$secondary-color: #e5ea26;

// LIGHT MODE
$light-top-center: #ffffff;
$light-background: #f1f1f1;
$light-background-options: #e4e2e2;
$light-left-right: #E8E8E8;
$light-text-color: #4e4e4e;
$light-border: 1px solid #adadc4;
$light-login-button: #2C2F74;
$light-controls-background: #d3d3d3;

// DARK MODE
$background: #14141B;
$center-section: #181923;
$text-color: #fafafa;
$dark-text-color: #73737a;
$layerOne: #1E1E29;
$border: 1px solid #666677;
$link-color: #147efb;
$highlight: #535361;
$login-button: #484DD0;
$controls-background: #525257;
//
// Dynamic Variables
//
:root{
    --background: #14141B;
    --layerOne: #1E1E29;
    --center-section: #181923;
    --text-color: #fafafa;
    --nav-text-color: #fafafa;
    --nav-background: #484DD0;
    --menu-text: #ffffff;
    --menu-text-hover: #14141B;
    --menu-list-hover: #f1f1f1;
    --header-color: #fafafa;
    --button-bg-color: #303035;
    --top-center: #14141B;
    --background-options: #e4e2e2;
    --scrollbar-color: #484DD0;
    --scrollbar-border: 2px solid #484DD0;
}
