@import './variables.scss';

:root {
    color-scheme: light dark;
}

body {
    margin: 0;
    overflow: hidden;
    background-color: var(--top-center);
    color: var(--text-color) !important;
}

mat-dialog-container,
#center-section,
#right-section {
    .headline-text-container {
        color: $text-color !important;
    }
}

main {
    display: flex;
    gap: calc(6em + 10px);
    #left-section {
        mat-nav-list {
            &.site-nav {
                padding-top: 50px;
                mat-list-item {
                    &.nav-item {
                        color: var(--text-color);
                        text-transform: capitalize;
                        width: 250px;
                        max-width: 100%;
                    }
                }
                .active-link {
                    background-color: var(--nav-background);
                    color: var(--nav-text-color) !important;
                    border-radius: 900px;
                }
            }
        }

        .left-section-footer {
            padding-bottom: 30px;
            line-height: 1.7;
        }
    }

    #left-section {
        background-color: var(--layerOne);
        height: calc(100vh - 80px);
        width: calc(2em +  250px);
    }

    #right-section,
    #center-section {
        flex: 1;
        background-color: var(--layerOne);
        min-width: 450px;
        height: calc(100vh - 80px);
    }

    #center-section {
        flex: 1.5 !important;
    }
}

.scroll-section,
app-contributor-article,
app-feed-item,
.scroll-style,
.mat-dialog-container {
    &::-webkit-scrollbar-track {
        background-color: var(--layerOne);
        height: 4px;
    }

    &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: var(--scrollbar-color);
        border: var(--scrollbar-border);
    }

    .slice {
        width: 70%;
        height: 1px;
        background: rgba(207, 216, 220, 0.2);
        margin: auto;
    }
}

.mat-form-field-appearance-outline {
    .mat-form-field-outline {
        color: var(--text-color) !important;
    }
}

@media only screen and (max-width: 500px) {
    body {
        overflow: auto;
    }
    main {
        flex-direction: column;
        gap: unset;

        #center-section {
            min-width: unset;
            height: 100%;
        }

        #left-section {
            overflow-x: scroll;
            position: sticky;
            top: 53px;
            z-index: 13;
            width: 100%;
            height: unset;
            mat-nav-list {
                &.site-nav {
                    display: flex;
                    padding: 10px 0;
                    mat-list-item {
                        &.nav-item {
                            width: unset;
                        }
                    }
                    .mat-list-text {
                        padding-left: 5px !important;
                    }
                }
            }
        }
        #right-section {
            display: none;
        }
    }
}

.chat {
    .core {
        gap: 0.5rem;
    }
}

@media (prefers-color-scheme: dark) { }

@media (prefers-color-scheme: light) {
    :root{
        --layerOne: #E8E8E8;
        --text-color: #4e4e4e;
        --background: #f1f1f1;
        --top-center: #ffffff;
        --nav-background: #ffffff;
        --button-bg-color: #ffffff;
        --background-options: #e4e2e2;
        --nav-text-color: #484DD0;
        --menu-text: #4e4e4e;
        --menu-text-hover: #484DD0;
        --menu-list-hover: #ffffff;
        --scrollbar-color: #cccccc;
        --scrollbar-border: 2px solid #cccccc;
    }
}
